import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import ContactForm from '../../components/ContactForm'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content ">
              <h1>Contact</h1>
              <div className="columns is-multiline">
                {/* <form className="column is-half"
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >

                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    Imię
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'message'}>
                    Wiadomość
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'message'}
                      onChange={this.handleChange}
                      id={'message'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="button is-link" type="submit">
                    Send
                  </button>
                </div>
              </form> */}

                <ContactForm />

                <div className="column is-half ">
                  <h4>email</h4>
                  <p>
                    <a href="mailto:broadwaydynamicauto@gmail.com">
                      broadwaydynamicauto@gmail.com
                  </a>
                  </p>

                  <h4>Phone</h4>
                  <div>
                    <a href="tel:+1-201-518-0942" className="button">Call now</a>
                    <ul style={{ listStyle: 'none', marginLeft: '0' }}>
                      <li ><a href="tel:201 518 0942">(201) 518-0942</a></li>
                      <li ><a href="tel:973 980 7875">(973) 980-7875</a></li>
                    </ul>
                  </div>
                  {/* <p>
                    <a href="tel:201 518 0942">(201) 518-0942</a>
                  </p>
                  <p>
                    <a href="tel:973 980 7875">(973) 980-7875</a>
                  </p> */}

                  <h3>Business Hours</h3>
                  <table class=" table is-striped is-hoverable">
                    <tbody>
                      <tr>
                        <th>Mon:</th>
                        <td><span>8:00 AM – 5:00 PM</span></td>
                      </tr>
                      <tr>
                        <th>Tue:</th><td><span>8:00 AM – 5:00 PM</span></td>
                      </tr>
                      <tr>
                        <th>Wed:</th><td><span>8:00 AM – 5:00 PM</span></td>
                      </tr>
                      <tr><th>Thu:</th><td><span>8:00 AM – 5:00 PM</span>
                      </td>
                      </tr>
                      <tr><th>Fri:</th><td><span>8:00 AM – 5:00 PM</span>
                      </td>
                      </tr>
                      <tr>
                        <th>Sat:</th><td><span>8:00 AM – 5:00 PM</span>
                        </td>
                      </tr>
                      <tr><th>Sun:</th><td><span>Closed</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>


                <div className="column is-half">



                  <h4>Broadway Dynamic Auto Body & Paint</h4>
                  <a className="button" href="https://www.google.com/maps/dir//Broadway+Dynamic+Auto+Body+%26+Paint/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c2f992f895ab81:0x6e4f54819488ad73!2m2!1d-74.056371!2d40.872" target="_blank" >Get directions</a>

                  <address style={{ padding: '15px 0px', fontStyle: 'normal' }}>
                    <div>256 Green Street</div>
                    <div>South Hackensack, NJ 07606</div>
                    <div>United States</div>
                  </address>

                </div>

              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
