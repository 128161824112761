import React from "react";
import { navigate } from "gatsby-link";
// import Recaptcha from "react-google-recaptcha";

// const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

function encode(data) {
  //use without fileinput
  // return Object.keys(data)
  //   .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
  //   .join('&')

  const formData = new FormData();

  //console.log("data", data)

  for (const key of Object.keys(data)) {
    //console.log("key, data[key]",key + " ," + data[key])
    formData.append(key, data[key]);
  }

  return formData;
}
//import { services } from '../data/services'
export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, isLoading: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  //fileinput
  handleAttachment = e => {
    //one file
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value });
  };

  handleSubmit = e => {
    this.setState({ isLoading: true });
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <form
        className="column is-half"
        name="contact"
        method="post"
        action="/contact/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        <div className="control field">
          <label className="label" htmlFor={"name"}>
            {/* Your Name: */}
            Name
          </label>
          <input
            className="input"
            placeholder="Name (required)"
            type={"text"}
            name={"name"}
            onChange={this.handleChange}
            id={"name"}
            required={true}
          />
        </div>
        <div className="field">
          <label className="label" htmlFor={"email"}>
            {/* Your Email: */}
            Email
          </label>
          <div className="control">
            <input
              className="input"
              placeholder="Email (required)"
              type={"email"}
              name={"email"}
              onChange={this.handleChange}
              id={"email"}
              required={true}
            />
          </div>
        </div>

        <div className="field">
          <label className="label" htmlFor={"phone"}>
            {/* Your Phone: */}
            Phone
          </label>
          <div className="control">
            <input
              className="input"
              placeholder="Phone (required)"
              type={"tel"}
              name={"phone"}
              onChange={this.handleChange}
              id={"phone"}
              required={true}
            />
          </div>
        </div>

        {/* <div className="field">

          <label className="file-label">
            <input
              // className="file-input"

              type="file"
              name="attachment"
              onChange={this.handleAttachment}
              //multiple
            />

          </label>

        </div> */}

        {/* <select className="w3-select" name="option" required>
      <option value="" disabled selected>
        Subject (required)
      </option>

      {services.map(service => (
        <option key={service} name={service} value={service.service}>
          {service.service}
        </option>
      ))}
      <option value="1">Other</option>
    </select> */}

        <div className="field">
          <label className="label" htmlFor={"message"}>
            Message:
          </label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Message (required)"
              name={"message"}
              onChange={this.handleChange}
              id={"message"}
              required={true}
            />
          </div>
        </div>
        {/* <div className="field">
          <Recaptcha
            ref="recaptcha"
            sitekey={RECAPTCHA_KEY}
            onChange={this.handleRecaptcha}
          />
        </div> */}
        <div className="field">
          <button
            className="button is-danger is-small"
            type="reset"
            value="Clean"
          >
            Clean
          </button>
        </div>
        <div className="field">
          <button
            disabled={this.state.isLoading}
            className={
              "button is-link is-medium " +
              (this.state.isLoading ? "is-loading" : "hidden")
            }
            type="submit"
            value="Send Message"
          >
            {this.state.isLoading ? "Sending" : "Send"}
          </button>
        </div>
      </form>
    );
  }
}
